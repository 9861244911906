/* eslint-disable max-lines */
import {
  LocalizedStringsWithSlug,
  RecordStatus,
  Sport
} from '@riotgames/api-types/elds/Common.type'
import { Stream } from '@riotgames/api-types/elds/Media.type'
import { BroadcastStream } from '@riotgames/api-types/elds/Schedules_v3.type'
import {
  Team,
  TeamCategory,
  TeamFormat
} from '@riotgames/api-types/elds/Teams.type'
import { unparse } from 'papaparse'
import { Config } from '../'
import EventBus from '../../services/EventBus/EventBus'
import { EnvObject } from '../Config/Config.type'
// TODO: figure out why moving this Logger import to the import above (`../`) breaks the build
import { debounce as _debounce } from 'lodash'
import { SyntheticEvent } from 'react'
import spacetime from 'spacetime'
import lolImage from '../../assets/logos/lol.png'
import valImage from '../../assets/logos/val.png'
import wrImage from '../../assets/logos/wr.png'
import tbdImage from '../../assets/tbd.png'
import EmpApiConfig from '../../services/EMP/EmpApiConfig'
import SingleSignOn from '../../services/SingleSignOn/SingleSignOn'
import Logger from '../Logger/Logger'
import { ProviderConfiguration, ProviderConfigurationString } from './Util.type'
import {
  Tournament,
  TournamentStatus
} from '@riotgames/api-types/elds/Tournaments.type'
import { NormalizedTournament } from '../../containers/Tournament/Tournament.type'

const log = new Logger('Util')
const defaultFetchOptions: RequestInit = {
  mode: 'cors',
  credentials: 'same-origin'
}

const sportLogos = {
  [Sport.Lol]: lolImage,
  [Sport.Val]: valImage,
  [Sport.Wr]: wrImage
}

const sportFullNames = {
  [Sport.Lol]: 'League of Legends',
  [Sport.Val]: 'Valorant',
  [Sport.Wr]: 'Wild Rift'
}

export const getSportFullName = (sport: Sport): string => sportFullNames[sport]
export const getSportLogo = (sport: Sport): string => sportLogos[sport]

export const getLocalizedName = (
  name: Maybe<LocalizedStringsWithSlug>
): string =>
  name?.localizations?.['en-US'] || name?.slug?.replace(/[-_]/g, ' ') || '-'

export const classNames = (
  ...args: MaybeNullable<string | boolean>[]
): string => args.filter((className) => !!className).join(' ')

export const mergeStyles = <T extends Record<string, Maybe<string>>>(
  defaultStyles: T,
  extensionStyles: Partial<T> = {}
): { [key in PropertyKey]: string } =>
    Object.fromEntries(
      Object.keys(defaultStyles).map((key) => [
        key,
        classNames(defaultStyles[key], extensionStyles[key])
      ])
    )

/**
 * Resizes an image through Akamai
 * @param {string} url - url to image you want resized
 * @param {Number} width - size in pixels you want the image width resized to
 * @param {Number} height - size in pixels you want the image height resized to
 *
 * Width and Height are optional.  If left blank, the Akamai service will
 * preserve the aspect ratio of the image.  If neither are provided this is a noop.
 */
export const resizeImage = (url?: string, width = 0, height = 0): string => {
  const akamaiUrl = Config.getKeyByEnv({
    prod: 'https://am-a.akamaihd.net/image/?resize=',
    local: 'https://am-a.akamaihd.net/image/?resize=',
    dev: 'https://am-a.akamaihd.net/image/?resize=',
    test: 'https://am-a.akamaihd.net/image/?resize='
  })
  // TODO: Remove once dev-static.lolesports.com and test-static.lolesports.com
  //  can be public (EMP Modernization Project)
  // Deprecated internal image resize service.
  const lolUrl = 'https://am.i.leagueoflegends.com/image/?resize='

  if (!url || !akamaiUrl) return ''

  if (window.devicePixelRatio > 1) {
    width > 0 && (width *= 2)
    height > 0 && (height *= 2)
  }

  const internalHostsOverride = [
    'assets-dev.riotesports.com',
    'assets-test.riotesports.com',
    'dev-static.lolesports.com',
    'test-static.lolesports.com'
  ]
  const allowedHosts = [
    'lolstatic-a.akamaihd.net',
    'assets.lolesports.com',
    'assets.riotesports.com',
    'assets-dev.riotesports.com',
    'assets-test.riotesports.com',
    'dev-static.lolesports.com',
    'test-static.lolesports.com',
    'static.lolesports.com',
    'cdn.leagueoflegends.com',
    'dev.assets.valorantesports.com',
    'test.assets.valorantesports.com',
    'assets.valorantesports.com'
  ]
  try {
    const urlObj = new URL(url)
    // Properly resize the image if it is on Akamai allowed domains list
    // and not already resized
    const host = urlObj.hostname
    if (allowedHosts.includes(host) && !urlObj.searchParams.has('resize')) {
      const resizeImageUrl = internalHostsOverride.includes(host)
        ? lolUrl
        : akamaiUrl
      return (
        resizeImageUrl
        + (width > 0 ? width : '')
        + ':'
        + (height > 0 ? height : '')
        + '&f='
        + window.encodeURIComponent(url)
      )
    }
  }
  catch (e) {
    // invalid URL
  }
  return url
}

/**
 * Takes an object containing URLs keyed by environment and returns a function
 * that builds the URL for a given endpoint, with optional query params
 *
 * Additionally accepts an optional parameter that will override the API environment to use
 */
export const urlBuilder
  = (urls: EnvObject, apiEnvOverride?: string): Function =>
    (endpoint: string, params?: Record<string, string>): string => {
      const url = Config.getKeyByEnv(urls, apiEnvOverride)
      const queryString = params
        ? `?${Object.entries(params)
          .map((pair) => pair.join('='))
          .join('&')}`
        : ''
      return url + endpoint + queryString
    }

/**
 * Returns a string of the format MM:SS representing elapsed time in game
 * @param {number} milliseconds Number representing a timestamp in milliseconds
 * @returns {string}
 */
export const formatTime = (milliseconds: number): string => {
  const sec = Math.floor(milliseconds / 1000) % 60
  const min = Math.floor(milliseconds / 60000)

  const minString = min < 10 ? `0${min}` : `${min}`
  const secString = sec < 10 ? `0${sec}` : `${sec}`

  return `${minString}:${secString}`
}

export const times = {
  DAY: 24 * 60 * 60 * 1000,
  DAYS: 24 * 60 * 60 * 1000,
  HOUR: 60 * 60 * 1000,
  HOURS: 60 * 60 * 1000,
  MINUTE: 60 * 1000,
  MINUTES: 60 * 1000,
  SECOND: 1000,
  SECONDS: 1000
}

const hoursMinutesSecondsRE = /(\d?\d):(\d?\d):(\d?\d)/
export const hmsTimestampToMs = (timestamp: string): number => {
  const hoursMinutesSeconds = timestamp.match(hoursMinutesSecondsRE)

  if (!hoursMinutesSeconds) return -1

  const [, hours, minutes, seconds] = hoursMinutesSeconds
  const hoursMs = parseInt(hours) * times.HOURS
  const minutesMs = parseInt(minutes) * times.MINUTES
  const secondsMs = parseInt(seconds) * times.SECONDS

  return hoursMs + minutesMs + secondsMs
}

export const msToHmsTimestamp = (milliseconds: number) => {
  if (!milliseconds) return

  const hours = Math.floor(milliseconds / times.HOURS)
  const minutes = Math.floor(milliseconds / times.MINUTES) % 60
  const seconds = Math.floor(milliseconds / times.SECONDS) % 60

  const [hText, mText, sText] = [hours, minutes, seconds].map((time) =>
    time < 10 ? `0${time}` : time
  )

  return `${hText}:${mText}:${sText}`
}

export const isUrlEncoded = (str: string) => /\\%/i.test(str)
export const encodeUrl = (url: string) => {
  if (isUrlEncoded(url)) {
    return url
  }
  return encodeURIComponent(url)
}

const requiresAccessToken = (url: string): boolean => {
  // eslint-disable-next-line no-restricted-syntax
  for (const p of EmpApiConfig.requireAccessTokenList()) {
    if (url.toLowerCase().includes(p.toLowerCase())) {
      return true
    }
  }
  return false
}

const sanitizeHeader = (headers: any) => {
  delete headers['x-api-key']
}

// wrapper to inject 'x-access-token' or 'okta authorization' header
// or any other additional headers to the request in the future
export const fetch = async (
  url: RequestInfo,
  options?: RequestInit,
  noAuthHeader = false,
  allowNotFound = false
): Promise<any> => {
  const injectAccessToken = EmpApiConfig.injectAccessToken()
  const { idToken, accessToken } = (await SingleSignOn.getTokens()) as any
  let optionsExtraHeaders = {
    ...options,
    headers: {
      ...options?.headers,
      Origin: window.location.origin,
      Authorization: `Bearer ${idToken}`
    }
  }

  if (noAuthHeader) {
    optionsExtraHeaders = {
      ...options,
      headers: {
        Origin: window.location.origin,
        ...options?.headers
      } as any
    }
  }
  // strip out any api keys. they will be handled from EMP Service
  sanitizeHeader(optionsExtraHeaders.headers)
  if (injectAccessToken && requiresAccessToken(url.toString())) {
    optionsExtraHeaders.headers['X-Access-Token'] = `${accessToken}`
  }
  return await _fetch(url, optionsExtraHeaders, allowNotFound)
}

const _fetch = async (
  url: RequestInfo,
  options?: RequestInit,
  allowNotFound = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const fetchOptions = options
    ? { ...defaultFetchOptions, ...options }
    : defaultFetchOptions
  try {
    const then: number = Date.now()
    const response = await window.fetch(url, fetchOptions)

    const hasSucceeded
      = allowNotFound && response.status === 404
      || response.status.toString().startsWith('2')

    if (!hasSucceeded) {
      // server returned non 2XX http status
      if ([401, 403].includes(response.status)) {
        const urlString = url.toString()
        const errorObject = {
          code: response.status,
          credentials: fetchOptions.credentials || 'same-origin',
          method: fetchOptions.method || 'GET',
          mode: fetchOptions.mode || 'cors',
          path: urlString.split('?')[0],
          queryParams: urlString.split('?')[1] || '',
          resource: urlString.split('/').pop()
        }
        EventBus.trigger('accessError', errorObject)
        // force redirection on AuthN failure
        if (response.status === 401) {
          log.info('(@) session expired. login required')
          await SingleSignOn.authenticate(true)
        }
      }

      // TODO: remove this when streamline api is fixed
      if ([500].includes(response.status)) {
        if (response.url.includes('streamline')) {
          // return empty json for streamline 500 errors
          return {}
        }
      }

      response.json().then((body) =>
        EventBus.trigger('message', {
          type: 'error',
          message: `${body.httpStatus} - ${body.message || body.errorCode}`
        })
      )

      throw Error(`${response.status}: ${response.statusText}`)
    }

    const delta: number = Date.now() - then
    if (delta > 1000) {
      log.warn(`${fetchOptions.method || 'GET'} ${url} took ${delta} ms.`)
    }
    log.debug(`${fetchOptions.method || 'GET'}: ${url} took ${delta} ms.`)

    const contentType = response.headers && response.headers.get('Content-Type')

    if (contentType === 'text/plain' || contentType === 'text/html') {
      return response.text()
    }
    else {
      // 204 - No Content will not work with response.json(), so we return nothing
      if (response.status === 204) {
        return {}
      }
      // Timeline service returns http 201 with empty text body and no content-type :-(
      else if (response.status === 201) {
        if (!contentType || contentType !== 'application/json') {
          return response.text()
        }
      }
      // 404 - Not Found w/ flag for allowing Not Found responses
      else if (allowNotFound && response.status === 404) {
        return undefined
      }
      return response.json()
    }
  }
  catch (error) {
    throw Error(`${error}: ${url}`)
  }
}

export const apiKeyBuilder = (keys: EnvObject): object => ({
  headers: { 'x-api-key': Config.getKeyByEnv(keys) }
})

export const getLocalStorageData = (key: string): object | undefined => {
  const item = window.localStorage.getItem(key)

  if (!item) {
    log.warn(`Garbage value stored in key ${key}, removing.`)
    window.localStorage.removeItem(key)

    return undefined
  }

  return JSON.parse(item)
}

export const getSessionStorageData = (key: string): any => {
  const item = window.sessionStorage.getItem(key)

  if (!item) {
    log.warn(`Garbage value stored in key ${key}, removing.`)
    window.sessionStorage.removeItem(key)

    return undefined
  }

  return JSON.parse(item)
}

export const setLocalStorageData = (key: string, value: object): void => {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export const setSessionStorageData = (key: string, value: any): void => {
  window.sessionStorage.setItem(key, JSON.stringify(value))
}

export const removeLocalStorageData = (key: string): void => {
  window.localStorage.removeItem(key)
}

export const removeSessionStorageStorageData = (key: string): void => {
  window.sessionStorage.removeItem(key)
}

// Capitalizes the first letter of each word.  "hello world" => "Hello World"
export const capitalize = (str: string): string =>
  str
    .split(' ')
    .map((part) => part.substr(0, 1).toUpperCase() + part.substr(1))
    .join(' ')

export const toSlug = (string: string, delimiter = '_'): string =>
  // remove special characters and replace space for underscore
  string
    .replace(/[^\w\s]/gi, '')
    .replace(/ /g, delimiter)
    .toLowerCase()

export const fromSlug = (slug: string, delimiter = /[_-]/g): string =>
  capitalize(slug.replace(delimiter, ' '))

// Does the samething as <linkState> function below.
// Only difference is this one is used for stateless components.
export const deriveState
  = (keyPath: string, eventPath?: string, callback?: Function) =>
    (event: any) => {
      const value = eventPath
        ? eventPath.split('.').reduce((acc, pathPart) => acc[pathPart], event)
        : event

      const reducer = (value: any, key: string): Record<string, any> => ({
        [key]: value
      })
      callback && callback(keyPath.split('.').reduceRight(reducer, value))
    }

/**
 * Links the value of a form element to a component's state.
 * @param component The component - usually passed in using 'this'
 * @param keyPath The key to assign the value to in the component's state
 * @param eventPath The path of the object to pull from, defaults to 'target.value'
 *
 * Usage: <input onChange={ linkState(this, 'myInput') }/>
 */
export const linkState
  = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: any,
    keyPath: string,
    eventPath?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) =>
    (event: any): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const reducer = (value: any, key: string): Record<string, any> => ({
        [key]: value
      })

      const value = eventPath
        ? eventPath.split('.').reduce((acc, pathPart) => acc[pathPart], event)
        : event

      const newState = keyPath.split('.').reduceRight(reducer, value)
      component.setState(newState)
    }

export const keyBy
  = (key: string) =>
  <T extends Record<string, any>>(arr: T[]): Record<string, T> =>
      arr.reduce(
        (acc, curr) => {
          acc[curr[key]] = curr
          return acc
        },
      {} as Record<string, T>
      )

export const keyById = keyBy('id')

/**
 * Returns a function that, when invoked, will only be triggered at most once
 * during a given window of time.
 *
 * By default the immediate parameter is set to true to cause debounce to trigger
 * the function on the leading edge instead of the trailing edge of the wait interval.
 *
 * To disable the leading-edge call set immediate to false (Useful when using it in combination with a search API or uncontrolled inputs)
 * @param func
 * @param timeout
 * @param immediate
 * @returns
 */
export function debounce<Params extends unknown[]> (
  func: (...args: Params) => unknown,
  timeout: number,
  immediate = true
): (...args: Params) => void {
  return _debounce(func, timeout, { leading: immediate })
}

export const sortBy
  = (key: string) =>
  <T extends Maybe<Record<string, any>>>(a: T, b: T): number => {
    if (a === undefined || a[key] === undefined) {
      return -1
    }
    if (b === undefined || b[key] === undefined) {
      return 1
    }

    if (a[key].toString().toLowerCase() < b[key].toString().toLowerCase()) {
      return -1
    }
    if (a[key].toString().toLowerCase() > b[key].toString().toLowerCase()) {
      return 1
    }

    return 0
  }

export const objectPropertiesFilter
  = (properties: string[], searchTerm: string) =>
    (object: Record<string, string>): boolean => {
      if (!object) return true
      if (searchTerm && searchTerm.length >= 2) {
        return properties
          .map((key) => object[key].toLowerCase())
          .some((value: string) => value.includes(searchTerm.toLowerCase()))
      }

      return true
    }

export const stopEventPropagation = (event: SyntheticEvent): void => {
  event.stopPropagation()
  event.preventDefault()
}

export const copyToClipboard = (text: string): Promise<void> =>
  window.navigator.clipboard.writeText(text)

export const tbdTeamId = '0'
export const buildTbdTeam = (): NullableProperties<Team> => {
  const category: TeamCategory = TeamCategory.Competitive
  const supportedFormats: TeamFormat[] = [TeamFormat._5v5]

  return {
    id: tbdTeamId,
    logoUrl: tbdImage,
    name: 'TBD',
    tricode: 'TBD',
    slug: 'tdb',
    homeLeagueId: '0',
    category,
    supportedFormats,
    roster: [],
    status: RecordStatus.Active,
    altLogoUrl: null,
    lightLogoUrl: null,
    altLightLogoUrl: null,
    altDarkLogoUrl: null,
    darkLogoUrl: null,
    plateImageUrl: null,
    backgroundImageUrl: null,
    photo: null,
    sport: Sport.Lol,
    organizationId: null,
    primaryColor: null,
    secondaryColor: null
  }
}

// allowMissinProperties: allow objects that don't have a property defined to
// still be equal to objects that have that property set to undefined.
// E.g: isEqual({b: 1}, {a: undefined, b: 1}, true) is `true`
export const isEqual = (
  x: any,
  y: any,
  allowMissingProperties = false
): boolean => {
  if (x === null || x === undefined || y === null || y === undefined) {
    return x === y
  }

  // after this just checking type of one would be enough
  if (x.constructor !== y.constructor) {
    return false
  }

  // if they are functions, they should exactly refer to same one (because of closures)
  if (x instanceof Function) {
    return x === y
  }

  // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
  if (x instanceof RegExp) {
    return x === y
  }

  if (x === y || x.valueOf() === y.valueOf()) {
    return true
  }

  if (Array.isArray(x) && x.length !== y.length) {
    return false
  }

  // if they are dates, they must had equal valueOf
  if (x instanceof Date) {
    return false
  }

  // if they are strictly equal, they both need to be object at least
  if (!(x instanceof Object)) {
    return false
  }

  if (!(y instanceof Object)) {
    return false
  }

  // recursive object equality check
  const p = Object.keys(x)
  return (
    Object.keys(y).every(
      (i): boolean =>
        p.indexOf(i) !== -1 || allowMissingProperties && y[i] === undefined
    ) && p.every((i): boolean => isEqual(x[i], y[i], allowMissingProperties))
  )
}

export const cloneDeep = <T extends {}>(item: T): T => {
  if (!item) {
    return item
  } // null, undefined values check

  let result: any

  const anyItem = item as any

  if (typeof result === 'undefined') {
    if (Object.prototype.toString.call(item) === '[object Array]') {
      result = []
      const arrayItem = item as unknown as Array<T>
      arrayItem.forEach((child, index) => {
        result[index] = cloneDeep(child)
      })
    }
    else if (typeof item === 'object') {
      if (!anyItem.prototype) {
        // check that this is a literal
        if (item instanceof Date) {
          result = new Date(item)
        }
        else {
          // it is an object literal
          result = {}
          for (const i in item) {
            result[i] = cloneDeep(item[i] as Record<string, any>)
          }
        }
      }
      else {
        result = item
      }
    }
    else {
      result = item
    }
  }

  return result as T
}

export const insertInto = <T>(
  array: T[],
  value: T,
  index: number = array.length
): T[] => {
  const arr = [...array]
  arr.splice(index, 0, value)
  return arr
}

/**
 * Replaces an item at a given index and returns a new array with the updated value
 * @param array Array to update
 * @param value New value to insert
 * @param index Location in array to update
 */
export const replaceAt = <T>(array: T[], value: T, index: number): T[] => {
  const a = [...array]
  a[index] = value
  return a
}

/**
 * Creates an array of a desired length and populates that array with indicies
 * e.g. createArray(5) => [0,1,2,3,4]
 * @param length the length of the new array
 */
export const createArray = (length: number): number[] =>
  Array.from(Array(length), (_, x) => x)

/**
 * Converts a number to its ordinal representation (e.g. 1 -> 1st)
 * @param num the number to convert
 */
export const toOrdinal = (num: number): string => {
  const str = String(num)
  // Covers 10-19
  if (/1\d$/g.test(str)) return `${str}th`
  // Covers numbers ending in 1, e.g. 1, 21, 31
  if (/1$/g.test(str)) return `${str}st`
  // Covers numbers ending in 2, e.g. 2, 22, 32
  if (/2$/g.test(str)) return `${str}nd`
  // Covers numbers ending in 3, e.g. 3, 23, 33
  if (/3$/g.test(str)) return `${str}rd`
  // Covers everything else
  return `${str}th`
}

export const toTitleCase = (text: string): string =>
  text
    .replace(/[-_]/g, ' ')
    .toLowerCase()
    .replace(/(^| )(\w)/g, (char) => char.toUpperCase())

/**
 * Updates a value on an object without mutating the original object
 */
export const set
  = <K>(key: string) =>
  <T>(obj: T, value: K): T => ({
      ...obj,
      [key]: value
    })

/**
 * Gets a value from an object.  Returns undefined if the key does not exist
 * on the object or if the object itself is undefined
 *  */
export const get
  = <T>(key: string) =>
    (obj: object): Maybe<T> =>
      obj ? obj[key as keyof typeof obj] : undefined

export const getParameter = (stream: Stream | BroadcastStream): string => {
  const providerConfiguration
    = `${stream.provider}Configuration` as ProviderConfigurationString
  const config = stream[providerConfiguration] as ProviderConfiguration
  return config.videoId || config.channelId || config.parameter
}

export const pipe = (...fns: Function[]): Function =>
  fns.reduce(
    (prev, next) =>
      (...args: any[]) =>
        next(prev(...args))
  )

export const compose = (...fns: Function[]): Function =>
  fns.reduce(
    (prev, next) =>
      (...args: any[]) =>
        prev(next(...args))
  )

export const updateDate = (
  oldDate: Date,
  newDate: Date,
  timezone: string | null
): Date => {
  // Convert both dates to spacetime objects to work with timezones easier
  let spaceDateToUpdate = spacetime(oldDate)
  let newSpaceDate = spacetime(newDate)
  // Move dates to currently selected timezone
  spaceDateToUpdate = spaceDateToUpdate.goto(timezone)
  newSpaceDate = newSpaceDate.goto(timezone)
  // Set the date on the date to the new date
  spaceDateToUpdate = spaceDateToUpdate
    .year(newSpaceDate.year())
    .month(newSpaceDate.month())
    .date(newSpaceDate.date())
  // Convert back to native date
  return spaceDateToUpdate.toNativeDate()
}

export const updateTime = (
  oldDate: Date,
  newTime: Date,
  timezone: string | null = null
): Date => {
  // Convert both dates to spacetime objects to work with timezones easier
  let spaceDateToUpdate = spacetime(oldDate)
  let newSpaceDate = spacetime(newTime)
  // Move dates to currently selected timezone
  spaceDateToUpdate = spaceDateToUpdate.goto(timezone)
  newSpaceDate = newSpaceDate.goto(timezone)
  // Set the time on the date to the new time
  spaceDateToUpdate = spaceDateToUpdate
    .hour(newSpaceDate.hour())
    .minute(newSpaceDate.minute())
    .second(0)
  // Convert back to native date
  return spaceDateToUpdate.toNativeDate()
}

// Retrieves a value from an object.  Optionally returns a default if either the
// object or the value is undefined.
export function getSafe<T, K extends string & keyof T>(
  obj: T,
  key: K,
  fallback?: T[K],
): T[K]
export function getSafe<T, V = undefined>(obj: T, key: string, fallback?: V): V
export function getSafe<T, V = undefined> (obj: T, key: string, fallback?: V) {
  return obj ? obj[key as keyof typeof obj] || fallback : fallback
}

// Sets a value on an object by returning a new object with the updated value.
// Does not mutate the original object.
export const setSafe = <T, K extends keyof T>(
  obj: T,
  key: K,
  value: T[K]
): T => ({
    ...obj,
    [key]: value
  })

// Sets a value for each object in the record by returning a new record with a new object with the updated value.
// Does not mutate the original object or record
export const setAllSafe = <T, K extends keyof T>(
  record: Record<string, T>,
  key: K,
  value: T[K]
) =>
    Object.entries(record).reduce(
      (acc, [k, v]: [string, T]) => {
        acc[k] = setSafe(v, key, value)
        return acc
      },
    {} as Record<string, T>
    )

// A version of setSafe that works with the Compose uitility.  Makes setting
// deeply nested values simpler.
export const setCurried
  = <T, K extends keyof T>(obj: T, key: K) =>
    (value: T[K]): T =>
      setSafe(obj, key, value)

export const omit = <T, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Omit<T, K> => {
  const omitOne = (obj: T, key: K): T => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [key]: omitted, ...rest } = obj
    return rest as T // Needed to type check agains the reduce function
  }

  return keys.reduce(omitOne, obj)
}

export const getRandomInt = (min: number, max: number): number =>
  Math.round(Math.random() * max) - min

export const generateStructuralId = (prefix?: string): string => {
  // This is a very naive ID generator.  It will create a "unique enough" id for
  // small quantities of IDs, such as the number of unique IDs we're talking
  // about for a Bracket or the number of Stages in a Tournament.
  const alphabet = '1234567890abcdef'
  let id = prefix ? `${prefix}_` : ''

  for (let index = 0; index < 10; index++) {
    const rand = getRandomInt(0, 256) % alphabet.length
    id += alphabet[rand]
  }

  return id
}

export const chunkArray = <T>(arr: T[], size: number): T[][] => {
  const tmpArray: T[][] = []

  for (let i = 0; i < arr.length; i += size) {
    tmpArray.push(arr.slice(i, i + size))
  }

  return tmpArray
}

// Throws an error if it's ever invoked.  Used to produce compile-time errors
// when we've failed to exhaust all possible code branches, such as handling
// every possible value for an enum.
export const assertNever = (x: never): never => {
  throw new Error('Unexpected object: ' + x)
}

// Wraps a paginated API so it looks like a single request/response. Iteratively
// makes calls to the api with new tokens as responses come in and returns a
// promise containing an array with the response from all individual calls
export const fetchPaginated = <T, K>(
  api: (token: string | undefined) => Promise<T>,
  mapResponseToPaginationToken: (response: T) => string | undefined,
  mapResponsesToValue: (responses: T[]) => K
): Promise<K> =>
    new Promise(async (resolve, reject) => {
    // eslint-disable-next-line no-undef-init -- needed so that the call to api(token) satisfies the type checker
      let token: string | undefined = undefined
      const responses: T[] = []

      try {
        do {
          const res = await api(token)
          token = mapResponseToPaginationToken(res)

          responses.push(res)
        } while (token)
      }
      catch (err) {
        reject(err)
      }

      resolve(mapResponsesToValue(responses))
    })

export const exportToCSV = (object: {}[], fileName: string) => {
  // Explanation:
  // Because the Browser does not let us just "send" a file to a user
  // we have to append a DOM node to the document and interact with it
  // this way.
  const csvString = unparse(object)
  const csvData = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
  const csvUrl = window.URL.createObjectURL(csvData)
  const downloadLink = window.document.createElement('a')
  downloadLink.href = csvUrl
  const now = new Date()
  downloadLink.download = `${
    object.length
  } ${fileName} - ${now.toISOString()}.csv`
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

// Check if an object is empty
export const hasNoKeys = (obj: MaybeNullable<Object>): boolean =>
  obj ? Object.keys(obj).length === 0 : true

export const isNullOrUndefined = (obj: any): boolean =>
  obj === null || typeof obj === 'undefined'

export function getColorsForRarity (gradient: string | undefined = '') {
  const getContrastColors = (inverse = false) => ({
    highContrastColor: !inverse ? '#000000' : '#ffffff',
    overlayColor: !inverse ? '#ffffff' : '#000000'
  })

  const defaultColors = getContrastColors(false)

  if (!gradient) return defaultColors

  const stops: string[] = []
  const splitGradient = gradient.split(',')

  // parse gradient stops from a string like the following:
  // background: radial-gradient(circle, #05259b 3%, #0034f7 60%);
  for (let i = 1; i < splitGradient.length; i++) {
    const piece = splitGradient[i]
    const [color] = piece.replace(')', '').trim().split(' ')
    stops.push(color)
  }

  if (!stops?.length) {
    return defaultColors
  }

  const color = stops[stops.length - 1]
  // default to black text if not hex color
  if (!color.startsWith('#')) {
    return defaultColors
  }

  // For reference:
  // https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
  const red = parseInt(color.slice(1, 3), 16)
  const green = parseInt(color.slice(3, 5), 16)
  const blue = parseInt(color.slice(5, 7), 16)

  const contrast = red * 0.299 + green * 0.587 + blue * 0.114
  const magicContrastValue = 186
  return getContrastColors(contrast <= magicContrastValue)
}

// Wraps generic promises to catch errors to avoid nested try/catch statements
export const to = (promise: Promise<any>) =>
  promise.then((data) => [null, data]).catch((err) => [err])
export const toAll = (promises: Promise<any>[]) =>
  Promise.all(promises)
    .then((data) => [null, data])
    .catch((err) => [err])

const formatStringToCamelCase = (str: string) => {
  const splitted = str.split('-')
  if (splitted.length === 1) return splitted[0]
  return (
    splitted[0]
    + splitted
      .slice(1)
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join('')
  )
}

export const getStyleObjectFromString = (str: string) => {
  const style: Record<string, string> = {}
  str.split(';').forEach((el) => {
    const [property, value] = el.split(':')
    if (!property) return

    const formattedProperty = formatStringToCamelCase(property.trim())
    style[formattedProperty] = value?.trim()
  })

  return style
}

export const displayTimeInTimezone = (
  date: Date | string,
  timezone: string,
  format: string = `time-24`
) => {
  const spaceDate = spacetime(date)
  const timezoneDate = spaceDate.goto(timezone)
  return timezoneDate.format(format)
}

export const isTournamentOverOrArchived = (
  endTime: string,
  status: TournamentStatus
): boolean => {
  const today = new Date()
  const endDate = new Date(endTime)
  const endDateWithBuffer = new Date(endDate.getTime() + 14 * times.DAY)
  return endDateWithBuffer < today || status === TournamentStatus.Archived
}

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

export const getTrimmedString = (str: string, maxLength: number): string =>
  str.length <= maxLength - 3 ? str : `${str.slice(0, maxLength)}...`
