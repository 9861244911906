import { EventBus } from '..'
import { fetch } from '../../commons'
import { createThunk } from '../../store/actions/utils'

type PostMessageRequestBody = {
  type: 'configApproved' | 'configCreated'
  leagueName: string
  patch: string
  version: string
}

class Slack {
  static getOptions (method = 'POST'): RequestInit {
    return {
      method: method,
      headers: {
        'Content-Type': 'text/plain',
        'X-From': 'EMP-Netlify-0fwyc'
      }
    }
  }

  async postMessage (body: PostMessageRequestBody): Promise<void> {
    const options = Slack.getOptions('POST')
    return fetch(
      '/api/functions/post-slack-message',
      {
        ...options,
        body: JSON.stringify(body)
      },
      true
    ).catch((error) => {
      const errorMessage = `
        There was an error posting your update to Slack.
        Please reach out to #ask-esports-digital. ${error}
      `

      EventBus.trigger('message', {
        type: 'error',
        message: errorMessage
      })
    })
  }
}

const api = new Slack()
export default api

export const postSlackMessage = createThunk(
  'slack/postMessage',
  api.postMessage
)
