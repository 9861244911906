/* eslint-disable max-lines */
import {
  NameSource,
  TournamentRealmAccount,
  TournamentRealmAccountStatus
} from '@riotgames/api-types/elds/Accounts.type'
import { Sport } from '@riotgames/api-types/elds/Common.type'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import { isEqual, set } from '../../commons'
import { TriState } from '../../commons/Constants/Constants'
import { createAccounts } from '../../services/Elds/Elds.actions'
import { Action } from '../../store/Store.type'
import { SliceConfig, createSlice } from '../../store/actions/utils'
import { AccountEditPayload, AddAccountsPayload } from './Accounts.type'

type ModelShape = Nullable<TournamentRealmAccount>[]
type MaybeModelShape = Maybe<ModelShape>

export type TournamentRealmAccountMutable = TournamentRealmAccount & {
  edit?: boolean
  index?: number
}
// #region Reducers

// #region Account Slice
export const createAccount = (
  login = '',
  displayName = '',
  password = ''
): Nullable<TournamentRealmAccount> => ({
  groupId: null,
  id: null,
  playerId: null,
  enabledSports: [],
  status: TournamentRealmAccountStatus.Active,
  nameSource: NameSource.FromAccount,
  puuid: null,
  login: {
    current: null,
    proposed: login
  },
  password: {
    current: null,
    proposed: password
  },
  displayName: {
    current: null,
    proposed: displayName
  },
  summoners: [],
  deleteAfter: new Date(new Date().setHours(0, 0, 0, 0)).toISOString()
})

const accountsInitialState = [createAccount()]
const accountSlice = createSlice<ModelShape>({
  name: 'account',
  initialState: accountsInitialState,
  reducers: {
    addAccounts: (
      state: ModelShape,
      { payload }: Action<AddAccountsPayload>
    ) => {
      if (payload) {
        const accounts = payload.map(({ login, displayName, password }) =>
          createAccount(login, displayName, password)
        )
        if (accounts.length > 1) {
          return accounts
        }
        return state.concat(accounts)
      }
    },
    removeAccount: (state: MaybeModelShape, { payload }: Action<number>) =>
      state?.filter((_, index) => index !== payload),
    removeDuplicateAccounts: (
      state: MaybeModelShape,
      { payload }: Action<ModelShape[]>
    ) => state?.filter((account: any) => !account?.displayName?.hasCollision),
    removeInvalidAccounts: (
      state: MaybeModelShape,
      { payload }: Action<ModelShape[]>
    ) =>
      state?.filter(
        (account) =>
          !payload?.includes(account as unknown as TournamentRealmAccount[])
      ),
    updateLogin: (
      state: ModelShape,
      { payload }: Action<{ index: number; value: string }>
    ) =>
      state.map((account, index) => {
        if (index === payload?.index) {
          return {
            ...account,
            login: {
              current: null,
              proposed: payload.value
            }
          }
        }

        return account
      }),
    updateDisplayName: (
      state: ModelShape,
      {
        payload
      }: Action<{ index: number; value: string; hasCollision?: boolean }>
    ) =>
      state.map((account, index) => {
        if (index === payload?.index) {
          return {
            ...account,
            displayName: {
              current: null,
              proposed: payload.value,
              hasCollision: payload.hasCollision
            }
          }
        }

        return account
      }),
    updateGroupId: (
      state: ModelShape,
      { payload }: Action<{ index: number; value: string }>
    ) =>
      state.map((account, index) => {
        if (index === payload?.index) {
          return {
            ...account,
            groupId: payload.value
          }
        }

        return account
      }),
    updateSport: (
      state: ModelShape,
      { payload }: Action<{ index: number; value: string }>
    ) =>
      state.map((account, index) => {
        if (index === payload?.index) {
          return {
            ...account,
            enabledSports: [payload.value]
          }
        }

        return account
      }),
    updateDeleteAfter: (
      state: ModelShape,
      { payload }: Action<{ index: number; value: string }>
    ) =>
      state.map((account, index) => {
        if (index === payload?.index) {
          return {
            ...account,
            deleteAfter: payload.value
          }
        }

        return account
      }),
    clearAccounts: () => accountsInitialState,
    replaceAccounts: (state: ModelShape, { payload }: Action<ModelShape>) =>
      payload
  },
  extraReducers: {
    [createAccounts.success.type]: () => accountsInitialState
  }
} as SliceConfig<ModelShape>)

export const {
  addAccounts,
  clearAccounts,
  removeAccount,
  removeDuplicateAccounts,
  removeInvalidAccounts,
  replaceAccounts,
  updateLogin,
  updateDisplayName,
  updateGroupId,
  updateSport,
  updateDeleteAfter
} = accountSlice.actions

// #endregion

// #region Edit Account Slice
type EditModel = Record<string, TournamentRealmAccountMutable>
const editAccountInitialState = {} as EditModel

const editAccountSlice = createSlice({
  name: 'account',
  initialState: editAccountInitialState,
  reducers: {
    copyForEdit: (
      state: EditModel | undefined,
      { payload }: Action<TournamentRealmAccountMutable>
    ) => {
      if (payload) {
        return {
          ...state,
          [payload.id]: payload
        }
      }
    },
    editLogin: (state: EditModel, { payload }: Action<AccountEditPayload>) => {
      if (payload) {
        const account = state[payload.id]
        const setLogin = set('login')
        const updated = {
          ...state,
          [payload.id]: setLogin(account, {
            ...account.login,
            proposed: payload.value
          })
        }
        return updated
      }
    },
    editDisplayName: (
      state: EditModel,
      { payload }: Action<AccountEditPayload>
    ) => {
      if (payload) {
        const account = state[payload.id]
        const setDisplayName = set('displayName')

        return {
          ...state,
          [payload.id]: setDisplayName(account, {
            ...account.displayName,
            proposed: payload.value
          })
        }
      }
    },
    editPassword: (
      state: EditModel,
      { payload }: Action<AccountEditPayload>
    ) => {
      if (payload) {
        const account = state[payload.id]
        const setPassword = set('password')

        return {
          ...state,
          [payload.id]: setPassword(account, {
            ...account.password,
            proposed: payload.value
          })
        }
      }
    },
    editDeleteAfter: (
      state: EditModel,
      { payload }: Action<AccountEditPayload>
    ) => {
      if (payload) {
        return {
          ...state,
          [payload.id]: set('deleteAfter')(state[payload.id], payload.value)
        }
      }
    },
    editSport: (
      state: EditModel,
      { payload }: Action<AccountEditPayload<Sport>>
    ) => {
      if (payload) {
        const account = state[payload.id]
        const updatedAccount = {
          ...account,
          enabledSports: [payload.value]
        }
        return {
          ...state,
          [payload.id]: updatedAccount
        }
      }
    },
    editAccountGroup: (
      state: EditModel,
      { payload }: Action<AccountEditPayload>
    ) => {
      if (payload) {
        return {
          ...state,
          [payload.id]: set('groupId')(state[payload.id], payload.value)
        }
      }
    },
    editMutateOn: (
      state: EditModel,
      { payload }: Action<AccountEditPayload>
    ) => {
      if (payload) {
        return {
          ...state,
          [payload.id]: set('edit')(state[payload.id], true)
        }
      }
    },
    editMutateOff: (
      state: EditModel,
      { payload }: Action<AccountEditPayload>
    ) => {
      if (payload) {
        return {
          ...state,
          [payload.id]: set('edit')(state[payload.id], false)
        }
      }
    }
  }
} as SliceConfig<EditModel>)

export const {
  copyForEdit,
  editLogin,
  editDisplayName,
  editPassword,
  editDeleteAfter,
  editSport,
  editAccountGroup,
  editMutateOn,
  editMutateOff
} = editAccountSlice.actions
// #endregion Edit Account Slice

// #region UI Slice
const uiInitialState = {
  sport: Sport.Lol,
  groupId: '',
  deleteAfter: new Date(new Date().setHours(0, 0, 0, 0)).toISOString()
}

const uiSlice = createSlice({
  name: 'account',
  initialState: uiInitialState,
  reducers: {
    changeSport: (state, { payload }: Action<Sport>) =>
      set('sport')(state, payload),
    changeDeleteAfter: (state, { payload }: Action<string>) =>
      set('deleteAfter')(state, payload),
    changeGroup: (state, { payload }: Action<string>) =>
      set('groupId')(state, payload)
  },
  extraReducers: {
    [clearAccounts.type]: (state) => ({
      ...uiInitialState,
      sport: state?.sport ?? Sport.Lol
    }),
    [createAccounts.success.type]: (state) => ({
      ...uiInitialState,
      sport: state?.sport ?? Sport.Lol
    })
  }
} as SliceConfig<typeof uiInitialState>)

export const { changeSport, changeDeleteAfter, changeGroup } = uiSlice.actions
// #endregion UI Slice

// #region Account Remove
const removeInitialState: () => Record<string, any> = () => ({})
const removeSlice = createSlice({
  name: 'account',
  initialState: removeInitialState(),
  reducers: {
    resetRemoveAccounts: () => ({}) as Record<string, any>,
    addRemoveAccount: (state, { payload, meta }: Action<any>) => {
      if (payload) {
        const combinedState = {
          ...state,
          [payload]: { ...(meta as any), checkStatus: TriState.Checked }
        }
        return combinedState
      }
    },
    removeRemoveAccount: (state, { payload }: Action<string>) => {
      if (payload && state) {
        const combinedState = {
          ...state
        }
        delete combinedState[payload]
        return combinedState
      }
    },
    setAllRemoveAccounts: (
      state,
      {
        payload
      }: Action<{ accounts: TournamentRealmAccount[]; checked: TriState }>
    ) => {
      if (payload) {
        const { accounts, checked } = payload
        const clone = {
          ...state
        }
        accounts?.forEach((account) => {
          clone[account.id] = { ...account, checkStatus: checked }
        })
        return clone
      }
    }
  }
} as SliceConfig<Record<string, any>>)
export const {
  resetRemoveAccounts,
  addRemoveAccount,
  removeRemoveAccount,
  setAllRemoveAccounts
} = removeSlice.actions
// #endregion Account Remove

// #region Account Export
const exportInitialState: () => Record<string, any> = () => ({})
const exportSlice = createSlice({
  name: 'account',
  initialState: exportInitialState(),
  reducers: {
    resetExportAccounts: () => ({}) as Record<string, any>,
    addExportAccount: (state, { payload, meta }: Action<any>) => {
      if (payload) {
        const combinedState = {
          ...state,
          [payload]: { ...(meta as any), checkStatus: TriState.Checked }
        }
        return combinedState
      }
    },
    removeExportAccount: (state, { payload }: Action<string>) => {
      if (payload && state) {
        // return omit<Record<string, any>, any>(state, payload)
        const combinedState = {
          ...state
        }
        delete combinedState[payload]
        return combinedState
      }
    },
    setAllExportAccounts: (
      state,
      {
        payload
      }: Action<{ accounts: TournamentRealmAccount[]; checked: TriState }>
    ) => {
      if (payload) {
        const { accounts, checked } = payload
        const clone = {
          ...state
        }
        accounts?.forEach((account) => {
          clone[account.id] = { ...account, checkStatus: checked }
        })
        return clone
      }
    }
  }
} as SliceConfig<Record<string, any>>)

export const {
  addExportAccount,
  removeExportAccount,
  resetExportAccounts,
  setAllExportAccounts
} = exportSlice.actions
// #endregion Account Export

// #region Account Duplicate Slice
const duplicateInitialState: ModelShape = []
const duplicateSlice = createSlice<ModelShape>({
  name: 'account',
  initialState: duplicateInitialState,
  reducers: {
    addDuplicateAccount: (
      state: ModelShape,
      { payload }: Action<ModelShape>
    ) => {
      if (payload) {
        return state.concat(payload)
      }
    },
    removeDuplicateAccount: (
      state: MaybeModelShape,
      { payload }: Action<number>
    ) => state?.filter((_, index) => index !== payload),
    clearDuplicateAccounts: () => duplicateInitialState
  }
} as SliceConfig<ModelShape>)

export const {
  addDuplicateAccount,
  clearDuplicateAccounts,
  removeDuplicateAccount
} = duplicateSlice.actions
// #endregion Account Duplicate Slice

// #region Account Existing Slice
const existingInitialState: string[] = []
const existingSlice = createSlice<string[]>({
  name: 'account',
  initialState: [],
  reducers: {
    addExistingAccounts: (
      state: string[],
      { payload }: Action<Record<string, string>[]>
    ) => {
      if (payload) {
        const displayNames = payload.map((account) =>
          account.displayName.toLowerCase()
        )
        const newState = [...new Set(state.concat(displayNames))]
        return newState
      }
    },
    removeExistingAccount: (state: [], { payload }: Action<number>) =>
      state?.filter((_, index) => index !== payload),
    clearExistingAccounts: () => existingInitialState
  }
} as unknown as SliceConfig<string[]>)

export const {
  addExistingAccounts,
  clearExistingAccounts,
  removeExistingAccount
} = existingSlice.actions
// #endregion Account Existing Slice

// #region Account Invalid Slice
const invalidInitialState: ModelShape = []
const invalidSlice = createSlice<ModelShape>({
  name: 'account',
  initialState: invalidInitialState,
  reducers: {
    addInvalidAccount: (state: ModelShape, { payload }: Action<ModelShape>) => {
      if (payload) {
        return state.concat(payload)
      }
    },
    removeInvalidAccount: (
      state: MaybeModelShape,
      { payload }: Action<number>
    ) => state?.filter((_, index) => index !== payload),
    clearInvalidAccounts: () => invalidInitialState
  }
} as SliceConfig<ModelShape>)

export const { addInvalidAccount, clearInvalidAccounts, removeInvalidAccount }
  = invalidSlice.actions
// #endregion Account Invalid Slice

// #region Account CSV
const accountsCSVInitialState: MaybeModelShape = []
const accountsCSVSlice = createSlice({
  name: 'account',
  initialState: accountsCSVInitialState,
  reducers: {
    setAccountsCSV: (state, { payload }: Action<MaybeModelShape[]>) => payload,
    clearAccountsCSV: () => accountsCSVInitialState
  }
} as SliceConfig<MaybeModelShape>)

export const { setAccountsCSV, clearAccountsCSV } = accountsCSVSlice.actions
// #endregion Account CSV

// #region Account tab
const accountsTabInitialState = 'valid'
const accountsTabSlice = createSlice({
  name: 'account',
  initialState: accountsTabInitialState,
  reducers: {
    changeAccountsTab: (state, { payload }: Action<string>) => payload
  }
} as SliceConfig<string>)

export const { changeAccountsTab } = accountsTabSlice.actions
// #endregion Account tab

const combinedReducer = combineReducers({
  accounts: accountSlice.reducer,
  accountsCSV: accountsCSVSlice.reducer,
  duplicate: duplicateSlice.reducer,
  existing: existingSlice.reducer,
  edit: editAccountSlice.reducer,
  export: exportSlice.reducer,
  invalid: invalidSlice.reducer,
  remove: removeSlice.reducer,
  tab: accountsTabSlice.reducer,
  ui: uiSlice.reducer
})

export default combinedReducer
type StateShape = ReturnType<typeof combinedReducer>
// #endregion Reducers

// #region Selectors
export const getAccounts = (state: StateShape) => state.accounts

const getDuplicateAccounts = (state: StateShape) => state.duplicate
const getExistingAccounts = (state: StateShape) => state.existing
const getEditAccounts = (state: StateShape) => state.edit
const getAccountsCSV = (state: StateShape) => state.accountsCSV
const getExport = (state: StateShape) => state.export
const getInvalidAccounts = (state: StateShape) => state.invalid
const getRemoveAccounts = (state: StateShape) => state.remove
const getTab = (state: StateShape) => state.tab
const getUI = (state: StateShape) => state.ui

const isAccountsSliceDirty = createSelector(
  [getAccounts],
  (accounts) => !isEqual(accounts, accountsInitialState)
)

const isUISliceDirty = createSelector(
  [getUI],
  (ui) => !isEqual(ui, uiInitialState)
)

const isAccountsInvalid = createSelector(
  [getInvalidAccounts],
  (invalid) => invalid.length > 0
)

export const isWIPAccountsDirty = createSelector(
  [isAccountsSliceDirty, isUISliceDirty],
  (accounts, ui) => accounts || ui
)

export const isWIPAccountsInvalid = createSelector(
  [isAccountsInvalid],
  (invalid) => invalid
)

export const getSelectedSport = createSelector([getUI], (ui) => ui.sport)
export const getAccountGroupId = createSelector([getUI], (ui) => ui.groupId)
export const getExpirationDate = createSelector([getUI], (ui) => ui.deleteAfter)

export const getAccountById = createSelector(
  [getEditAccounts, (_: any, id: string) => id],
  (accounts, id) => accounts[id]
)

export const getAccountInMemoryById = createSelector(
  [getAccounts, (_: any, id: string) => id],
  (accounts, id) => accounts.filter((account) => account?.id === id)[0]
)

export const isEditAccountDirty = createSelector(
  [
    getAccountById,
    (state, accountId, account: TournamentRealmAccount) => account
  ],
  (model, account) => !isEqual(model, account)
)

// This function is used to check if the pending account and display name have updated yet.
// If the account and display name have changed, the redux store should update to reflect
// the new values that have come in.
export const isEditAccountDisplayLoginDirty = createSelector(
  [
    getAccountById,
    (state, accountId, account: TournamentRealmAccount) => account
  ],
  (model, account) =>
    model?.displayName?.current !== account?.displayName?.current
    || model?.login?.current !== account?.login?.current
)

export const getExportAccountIds = createSelector([getExport], (exports) =>
  Object.keys(exports)
)
export const getExportAccountHashmap = createSelector(
  [getExport],
  (exports) => exports
)

export const getRemoveAccountHashmap = createSelector(
  [getRemoveAccounts],
  (remove) => remove
)

export const getCSVAccounts = createSelector([getAccountsCSV], (csv) => csv)

export const getDuplicateAccountsArr = createSelector(
  [getDuplicateAccounts],
  (duplicate) => duplicate
)

export const getExistingAccountsArr = createSelector(
  [getExistingAccounts],
  (existing) => existing
)

export const getInvalidAccountsArr = createSelector(
  [getInvalidAccounts],
  (invalid) => invalid
)

export const getWIPEditAccounts = createSelector(
  [getEditAccounts],
  (edit) => edit
)

export const getWIPAccountsTab = createSelector([getTab], (tab) => tab)
// #endregion
