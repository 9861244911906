import { Sport } from '@riotgames/api-types/elds/Common.type'
import { MouseEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import empImage from '../../assets/logos/logo.png'
import { classNames, getSportLogo } from '../../commons'
import { AppWindow } from '../../index.type'
import { getSport } from '../../store/reducers'
import { changeSport as changeSportForAccountCreation } from '../../containers/Accounts/Accounts.slice'
import { setSport } from '../../store/reducers/UserConfig/UserConfig'
import { Dropdown, createOption } from '../Dropdown/Dropdown'
import Message from '../Message/Message'
import styles from './Nav.scss'
import { navItems } from './NavItems'

type Props = {
  selectedSport?: Sport
  setSport?: (sport: Sport) => void
}

export const Nav = (props: Props) => {
  const navigate = useNavigate()

  const appWindow = window as AppWindow
  const version = appWindow.APP_VERSION
  const navHeight = 66
  const minSlackChannelPadding = 20

  const [selectedPath, setSelectedPath] = useState(window.location.pathname)
  const [slackChannelPadding, setSlackChannelPadding] = useState(
    navHeight + minSlackChannelPadding
  )

  const selectedSport = props.selectedSport || useSelector(getSport)

  const dispatch = useDispatch()
  const setSportFunc = (sport: Sport) =>
    props.setSport ? props.setSport(sport) : dispatch(setSport(sport))

  const addListeners = () => {
    document.addEventListener('scroll', () => {
      const scrollTop
        = document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop < navHeight) {
        setSlackChannelPadding(navHeight + minSlackChannelPadding)
      }
    })
  }

  useEffect(() => {
    addListeners()
  }, [])

  const isInternalLink = (link: Nullable<string>): boolean =>
    link?.indexOf('/') === 0

  const clickNavItem = (event: MouseEvent): void => {
    const currentTarget = event.currentTarget as HTMLElement
    const link = currentTarget.getAttribute('href') || ''
    if (link && isInternalLink(link)) {
      setSelectedPath(link)
    }
  }

  const renderNavItem = (item: { name: string; link: string }): JSX.Element => {
    const target = isInternalLink(item.link) ? '_self' : '_blank'
    const isSelected = selectedPath.indexOf(item.link) === 0
    const classes = classNames(styles.item, isSelected && styles.selected)

    return (
      <a
        href={ item.link }
        className={ classes }
        key={ item.name }
        target={ target }
        data-selected-path={ selectedPath || '' }
        onClick={ (event: MouseEvent): void => clickNavItem(event) }>
        <div className={ styles.title }>{ item.name }</div>
      </a>
    )
  }

  const selectSport = (sport: Sport): void => {
    setSportFunc(sport)
    const currentPath = window.location.pathname
    if (currentPath.includes('details') || currentPath.includes('edit')) {
      const newPath = `/${currentPath.split('/')[1]}/list/current`
      setSelectedPath(newPath)
      navigate(newPath)
    }
  }

  const renderSportDropdown = (): JSX.Element => {
    const options = Object.values(Sport).map((sport) =>
      createOption('', sport, getSportLogo(sport))
    )

    return (
      <Dropdown
        sportSelector
        hideSelectedInMenu
        class={ styles.sportDropdown }
        options={ options }
        value={ selectedSport }
        onSelect={ ({ value }) => {
          dispatch(changeSportForAccountCreation(value as Sport))
          selectSport(value as Sport)
        } }
      />
    )
  }

  const renderSlackChannel = (): JSX.Element => (
    <a
      href="https://riotgames.slack.com/app_redirect?channel=ask-esports-digital"
      className={ styles.slack }
      style={ { top: `${slackChannelPadding}px` } }
      id="slack-link-floating-button"
      target="_blank" rel="noreferrer">
      Questions? #ask-esports-digital
    </a>
  )

  const navBarClasses = classNames(styles.navBar, styles[selectedSport])

  return (
    <div className={ styles.nav } data-testid="nav-bar">
      <div className={ navBarClasses } style={ { height: `${navHeight}px` } }>
        { renderSportDropdown() }
        <img className={ styles.logo } src={ empImage }/>
        { navItems.map((item) => !item.hide && renderNavItem(item)) }
        <span className={ styles.version }>v{ version }</span>
        { renderSlackChannel() }
      </div>
      <Message/>
    </div>
  )
}

export default Nav